import React, { Component } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import { Container } from "../../global"
import { Chargebee, Buy} from "../chargebee"


const Chooser = () => {
  const data = useStaticQuery(query);

  return (
            <PriceContainer>
            <Chargebee />
                { data.site.siteMetadata.plans.map((plan, index) => {
                    return <Choice key={index} id={plan.id} title={ plan.header } storage={plan.storage} cost={plan.price} />
                })}
            </PriceContainer>
    )
}

const Choice = (props) => {
  return (  
  <PriceWrapper>
        <Title>{props.title}</Title>
        <Storage>{props.storage}GB</Storage>
        <Price>${props.cost}</Price>
        <Buttons>
          <StyledBuy plan={props.id} text='Buy'/>
        </Buttons>
  </PriceWrapper>
)}

export const query = graphql`
  query {
    site {
      siteMetadata {
        plans { 
            id,
            storage,
            price,
            header
        }
      }
    }
  }`

export default Chooser

const signupclick = (id) =>{
    console.log("Hi: " + id);
}

const PriceContainer = styled(Container)`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start
  background-color: grey;
  margin: 80px 0 0;
  padding: 0 0 80px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
`

const Buttons = styled(Container)`
  justify-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const StyledBuy = styled(Buy)`
  font-weight: 500;
  font-size: 14px;
  align: center;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
  align-self: flex-end;
`

const Title = styled.h4`
    text-align: center;
    justify-self: flex-start
    flex-grow: 1;
`

const PriceWrapper = styled(Container)`
    width: 220px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    box-sizing: border-box;
    background-color: lightgrey;
    padding: 12px;
    border: 2px solid darkgrey;
    margin: 12px;
    -webkit-transition: 0.3s;
    transition: 0.3s;

    :hover {
        box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2)
    }
}
`

const Storage = styled.div`
    text-align: center;
    justify-self: flex-end;
`

const Price = styled.div`
    text-align: center;
    justify-self: flex-end;
`

