import React from "react"
import { Link } from "gatsby"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
//import Navigation from "../components/common/navigation/navigation"

import Chooser from "../components/common/plans/chooser"

import { AuthService, useAuth } from "gatsby-theme-auth0";

//import { Container } from "../components/global"

const isBrowser = typeof window !== "undefined";

const PlanPage = () => {
    const { isLoggedIn, profile } = useAuth();
    if ( !isLoggedIn && isBrowser ) {
        // If we’re not logged in, redirect to the home page.
        AuthService.login()
        return null
      }

    return (
    <Layout>
        <SEO title="Choose Plan" />
        <Link to="/">
            Home
        </Link>
        <Chooser />
    </Layout>
)}

export default PlanPage
